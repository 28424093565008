import { Axios } from "../../../../util";
import { NotificationManager } from "react-notifications";

//-----------get user list with paginated data--------------------------//
export const getUserData = (params, setisLoader) => {
  setisLoader(true);
  return (dispatch) => {
    Axios.get(
      `/api/users/paginated?page=${params.page}&limit=${params.perPage}&search=${params.search}&user_type=${params.user_type}`
    )
      .then((response) => {
        dispatch({
          type: "GET_DATA_USER",
          allData: response.data.data.total,
          data: response.data.data.docs,
          totalPages: response.data.data.pages,
          params,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_DATA_USER",
          allData: 0,
          data: [],
          totalPages: 0,
          params,
        });
      })
      .finally(() => {
        setisLoader(false);
      });
  };
};

//-----------------create new user--------------------------//
export const createNewUser = (data, props, setisLoader) => {
  return (dispatch, getStore) => {
    Axios.post(`/api/users`, {
      name: data.name,
      mobile_number: `+91${data.mobile_number}`,
      email: data.email,
      user_status: JSON.parse(data.user_status),
      password: data.password,
      authentication:  data.authentication ? data.authentication : [],
      employee_code: data.employee_code ? data.employee_code :  null,
      designation: data.designation ? data.designation : null,
      department: data.department ? data.department : null,
      state: data.state,
      city: data.city,
      branch: data.branch ? data.branch : null,
      reporting_manager: data.reporting_manager,
      personal_number: data.personal_number,
      joining_date: data.joining_date ? data.joining_date : null,
      current_address: data.current_address,
      permanent_address: data.permanent_address,
      company: data.company,
      user_type: data.user_type,
      show_logs: data.show_logs,
      shift_id: data.shift_id ? data.shift_id : null,
      screenshot_allowed : data.screenshot_allowed,
      allowWithoutGeocodingAttendance : data.allowWithoutGeocodingAttendance,
      bankName : data.bankName ? data.bankName : null,
      bankAccountNo : data.bankAccountNo ? data.bankAccountNo : null,
      bankIFSCCode : data.bankIFSCCode ? data.bankIFSCCode : null,
      gstNumber : data.gstNumber ? data.gstNumber : null,
      gst : data.gst,
    })
      .then((response) => {
        if (response.data.status === false) {
          NotificationManager.error(response.data.message, "", 5000);
        } else {
          props.onMasterSave();
          NotificationManager.success(response.data.message);

          return (dispatch) => {
            dispatch({
              type: "CREATE_USER",
              data,
            });
          };
        }
      })
      .then(() => {
        dispatch(getUserData(getStore().userMasterReducer.params, setisLoader));
      })
      .catch((error) => {
        NotificationManager.error(
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong !"
        );
      });
  };
};

//------------------update user information------------------------//
export const updateUser = (data, props, setisLoader) => {
  return (dispatch, getStore) => {
    Axios.put(`/api/users/${props.preloadValue.detail._id}`, {
      name: data.name,
      mobile_number: `+91${data.mobile_number}`,
      email: data.email,
      user_status: JSON.parse(data.user_status),
      password: data.password,
      authentication: data.authentication ? data.authentication : [],
      employee_code: data.employee_code ? data.employee_code :  null,
      designation: data.designation ? data.designation : null,
      department: data.department ? data.department : null,
      state: data.state,
      city: data.city,
      branch: data.branch ? data.branch : null,
      reporting_manager: data.reporting_manager,
      personal_number: data.personal_number,
      joining_date: data.joining_date ? data.joining_date : null,
      current_address: data.current_address,
      permanent_address: data.permanent_address,
      company: data.company,
      user_type: data.user_type,
      show_logs: data.show_logs,
      shift_id: data.shift_id ? data.shift_id : null,
      screenshot_allowed : data.screenshot_allowed,
      allowWithoutGeocodingAttendance : data.allowWithoutGeocodingAttendance,
      bankName : data.bankName ? data.bankName : null,
      bankAccountNo : data.bankAccountNo ? data.bankAccountNo : null,
      bankIFSCCode : data.bankIFSCCode ? data.bankIFSCCode : null,
      gstNumber : data.gstNumber ? data.gstNumber : null,
      gst : data.gst,
    })
      .then((response) => {
        if (response.data.status === false) {
          NotificationManager.error(response.data.message);
        } else {
          props.onMasterSave();
          NotificationManager.success(response.data.message);
          return (dispatch) => {
            dispatch({
              type: "UPDATE_USER",
              data,
            });
          };
        }
      })
      .then(() => {
        dispatch(getUserData(getStore().userMasterReducer.params, setisLoader));
      })
      .catch(function (error) {
        NotificationManager.error(
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong !"
        );
        // toast.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !',
        //     { transition: Zoom, hideProgressBar: false, autoClose: 2000 })
      });
  };
};

//------------------update user status information------------------------//
export const updateUserStatus = (data, setisLoader) => {
  return (dispatch, getStore) => {
    Axios.put(`/api/users/${data.user_id}`, {
      user_status: JSON.parse(data.user_status),
      company: data.company,
    })
      .then((response) => {
        if (response.data.status === false) {
          NotificationManager.error(response.data.message);
        } else {
          NotificationManager.success(
            `User ${
              response.data.data.user_status ? "Activated" : "Deactivated"
            } successfully!`
          );
          return (dispatch) => {
            dispatch({
              type: "UPDATE_USER",
              data,
            });
          };
        }
      })
      .then(() => {
        dispatch(getUserData(getStore().userMasterReducer.params, setisLoader));
      })
      .catch(function (error) {
        NotificationManager.error(
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong !"
        );
        // toast.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !',
        //     { transition: Zoom, hideProgressBar: false, autoClose: 2000 })
      });
  };
};

//------------------get All Users information------------------------//
export const getAllUsersData = (params) => {
  let url = `api/users/getActiveUsers`;

  if (params) {
    url = `api/users/getActiveUsers?user_type=${params}`;
  }

  return (dispatch) => {
    Axios.get(url)
      .then((response) => {
        dispatch({
          type: "GET_DATA_All_ACTIVE_USER",
          data: response.data.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching active users data:", error);
        dispatch({
          type: "GET_DATA_All_ACTIVE_USER",
          data: [],
        });
      });
  };
};

//------------------get Sales Executive Users information------------------------//
export const getSalesExecutiveUsersData = () => {
  return (dispatch) => {
    Axios.get(`api/users/designation/app/all`)
      .then((response) => {
        dispatch({
          type: "GET_DATA_BD_EXECUTIVE_USER",
          data: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_DATA_BD_EXECUTIVE_USER",
          data: [],
        });
      });
  };
};

//------------------get Users data Branch wise--------------------------//
export const getUsersDataBranchWise = (params) => {
  return (dispatch) => {
    Axios.get(`/api/users/branch/${params.branch_id}`)
      .then((response) => {
        dispatch({
          type: "GET_DATA_USERS_BRANCH_WISE",
          data: response.data.data,
          params,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_DATA_USERS_BRANCH_WISE",
          data: [],
          params,
        });
      });
  };
};
