// ** Checks if an object is empty (returns boolean)
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import firebase from "@firebase/app";
import { NotificationManager } from "react-notifications";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const MySwal = withReactContent(Swal);
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: "login session has been expired ?",
    text: "login again to continue accessing your account !",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Yes, logout it!",
    customClass: {
      confirmButton: "btn btn-danger",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      firebase.auth().signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase
    .auth()
    .currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      // handleConfirmText();
    } else {
      return Promise.reject(error);
    }
  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export { Axios, AxiosInstance };



//upload image on s3 bucket  directly
export const uploadImageToS3 = (file) => {
  const foldername = 'profile-images'
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, null, foldername)
      .then((imageData) => {
        resolve(imageData);
      })
      .catch((error) => {
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};


//Delete files from S3.
export const deleteImageFromS3 = async (filepaths) => {
  // Handle empty array case
  if (!filepaths || filepaths.length === 0) {
    console.log("No file paths provided to delete.");
    return [];
  }

  const deleteFile = async (file) => {
    try {
      const s3UrlPrefix = "https://sellon-finserve.s3.ap-south-1.amazonaws.com/";
      const filePath = file.replace(s3UrlPrefix, "");
  
      const response = await Axios.post(`/presigned/generate-presigned-url-for-delete`, {
        filePath: filePath
      });
  
      if (response.data.status === false) {
        return ""
      } else {
        const presignedUrl = response.data.data;
        try {
          const response = await axios.delete(presignedUrl);
          if (response.status === 204) {
            console.log("File deleted successfully");
            return response
          } else {
            console.error("Failed to delete file", response);
            return ""
          }
        } catch (error) {
          console.error("Error deleting file from S3", error);
          return ""
        }
      }
    } catch (error) {
      console.error("Error uploading file or generating presigned URL:", error);
      return ""
    }
  };

  // asynchronous file deletions
  const results = await Promise.all(filepaths.map(deleteFile));
  return results;
};


//Upload Media file to S3.
export const UPLOAD_IMAGES = async (
  event,
  setMediaFile,
  setShowPreview,
  setIsVideo,
  setLoading
) => {
  setLoading(true);

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
    fieldName: dataArray.getAttribute("field-name"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false);
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false);
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (setIsVideo) {
    if (extension === "mp4" || extension === "mkv") {
      setIsVideo(true);
    } else {
      setIsVideo(false);
    }
  }

  const allowPdf = data.fieldName === "logo" ? null : "pdf";

  if (
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === allowPdf || // Allow PDF files
    event.target.accept.includes("video/") // Allow video files
  ) {

    // const maxSize = {
    //   width: 304,
    //   height: 88,
    // };

    const fileSizeLimit = data.fieldName === "logo" ? 512000 : 1000000;

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      const isImage = ["png", "jpg", "jpeg", "pdf"].includes(extension);
      const isVideo = ["mp4", "mkv", "mov"].includes(extension);

      if (
        (isImage && file.size <= fileSizeLimit) ||
        (isVideo && file.size <= 51000000)
      ) {
        // const image = new Image();

        // Read the image content as a data URL
        // const reader = new FileReader();
        // reader.onload = function (e) {
        //   image.src = e.target.result;

        // Check image dimensions after it's loaded
        // image.onload = async function () {
        // if (
        //   image.width === maxSize.width &&
        //   image.height === maxSize.height
        // ) {

        try {

          const foldername = "images"
          const fileUrl = await preSignedUploadImageToS3(file,null, foldername);
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          setMediaFile(fileUrl);
          setShowPreview(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
        // } else {
        //   dataArray.value = "";
        //   NotificationManager.error(
        //     `Image dimensions should be ${maxSize.width}x${maxSize.height}`,
        //     `File name: ${file.name}`
        //   );
        // }
        // };
        // };

        // reader.readAsDataURL(file);
      } else {
        dataArray.value = "";
        const sizeUnit = data.fieldName === "logo" ? "KB" : "MB";

        if (isImage) {
          NotificationManager.error(
            `Image size needs to be less than or equal to ${
              fileSizeLimit / (data.fieldName === "logo" ? 1000 : 1000000)
            }${sizeUnit}`,
            `File name: ${file.name}`
          );
        } else if (isVideo) {
          NotificationManager.error(
            `Video size needs to be less than or equal to 50 MB`, // Assuming 51000000 bytes is 50 MB
            `File name: ${file.name}`
          );
        }
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      `Only JPG, PNG, JPEG ${
        allowPdf === "pdf" ? ", PDF, or video" : ""
      } file types are allowed`,
      "Error"
    );
  }

  setLoading(false);
};

//Upload Thumbnail image to s3.
export const UPLOAD_THUMBNAIL_IMAGE = async (
  event,
  setThumbnailFile,
  setShowPreviewThumbnail
) => {
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (extension === "png" || extension === "jpg" || extension === "jpeg") {

    for (let i = 0; i < event.target.files.length; i++) {
      const imageFile = event.target.files[i];

      if (imageFile.size <= 1000000) {

        try {
          const foldername = "images"
          const fileUrl = await preSignedUploadImageToS3(imageFile,null, foldername)
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          setThumbnailFile(fileUrl);
          setShowPreviewThumbnail(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "File size needs to be less than or equal to 1mb",
          "File name: " + imageFile.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      "Only JPG or PNG or JPEG file types are allowed",
      "Error"
    );
  }
};


//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const preSignedUploadImageToS3 = async (file, setIsLoader, folderName) => {
  try {
   // Generate a 4-digit random number
   const randomNumber = Math.floor(1000 + Math.random() * 9000);
  
   // Get current timestamp
   const timestamp = Date.now();

    const response = await Axios.post(`/presigned/generate-presigned-url`, {
      filePath: `${folderName}/${timestamp}_${randomNumber}_${file.name}`,
      fileType: file.type
    });

    if (response.data.status === false) {
      NotificationManager.error("Failed to upload file!");
      // setIsLoader(false)
      return ""
    } else {
      const presignedUrl = response.data.data;
      const uploadResponse = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      if (uploadResponse.status === 200) {
        const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
        // setIsLoader(false)
        return fileUrl;
      } else {
        NotificationManager.error("Failed to upload file!");
        // setIsLoader(false)
        return ""
      }
    }
  } catch (error) {
    NotificationManager.error("Failed to upload file!");
    // setIsLoader(false)
    console.error("Error uploading file or generating presigned URL:", error);
    return ""
  }
};


export const formatCurrency = (value) => {
  if (value === undefined || value === null) {
    return "-";
  }
  return `₹${parseFloat(value).toFixed(2)}`;
};